<template>
  <div
    :class="{
    'folders': true,
    'folders--splitviewMode': splitviewMode
  }">
    <div class="heading">
      <div class="title">{{ category.categoryname }}</div>
      <div
        class="searchbar"
        v-if="showSearchBar">
        <form-input
          placeholder="Suche">
        </form-input>
      </div>
    </div>
    <folder-container :grid-size="4">
      <folder-element
        :key="element.id"
        :element="element"
        v-for="element in elements"
        :emitClickEvent="splitviewMode"
        @select="selectElement(element)">
        {{ element.categoryname }}
      </folder-element>
    </folder-container>
  </div>
</template>

<script>
  export default {
    props: [
      'category',
      'breadcrumb',
      'splitviewMode',
    ],
    computed: {
      showSearchBar() {
        return this.$helper.findAttributeValue(this.category, 'CMS-Configuration')?.includes('EnableAuthentication')
      }
    },
    data() {
      return {
        elements: null,
      }
    },
    async created() {
      this.elements = await this.$api.findCategories(this.$helper.extractIdFromSlug(this.category.id))
    },
    methods: {
      async selectElement(category) {
        const componentType = this.$helper.resolveComponentTypeByCategory(category)
        if (this.splitviewMode && componentType !== 'folder') {
          return this.$emit('selectElement', category)
        }
        this.elements = await this.$api.findCategories(this.$helper.extractIdFromSlug(category.id))
      }
    },
    inject: [
      '$api',
      '$helper',
    ],
    emits: [
      'selectElement'
    ]
  }
</script>

<style lang="scss" scoped>
  .folders {
    @apply
      md:p-0;

    &.folders--splitviewMode {
      @apply
        md:p-4;
    }

    .heading {
      @apply
        flex
        w-full
        items-center
        justify-between;

      .title {
        @apply
          text-2xl;
      }

      .searchbar {
        @apply
          w-full
          md:w-1/3;
      }
    }
  }
</style>